import React from "react";
import styled from "styled-components";
import { breakpoints, colors, eases } from "../../styles/variables";

export const Section = styled.section`
	.sectionClip {
		min-height: 100vh;
		position: relative;
		z-index: 1;
		background-position: bottom center;
		background-size: contain;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-color: ${colors.white};
		height: 100%;
		clip-path: polygon(0 0,100% 0,100% 100%,61% calc(100% - 5.5vw),61.5% calc(100% - 2.25vw),0 calc(100% - 11.5vw));
	}

	// Overall layout
	.gridOut {
		min-height: 100%;
		padding-top: 200px;
		padding-bottom: 11.5vw;
	}
	.gridIn {
		grid-column: 1 / span 12;
	}

	// Tabs section
	.topNavDropdown {
		display: none;
		padding-top: 38px;
	}
	.topNavTabs {
		display: block;
		background-color: ${colors.white};
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		height: 50px;
		position: relative;

		.tabOuter {
			width: 16.66%;
			height: 100%;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 4px solid ${colors.cream};
			.text-caption {
				color: ${colors.dark04};
				transition: color 0.5s ease-out;
			}
			&.active {
				.text-caption {
					color: ${colors.primary};
				}
			}
		}
		.tabHighlight {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 16.66%;
			height: 4px;
			background-color: ${colors.primary};
			transition: transform 0.5s ${eases.inOut};
		}
	}

	// Gallery grid
	.galleryOut {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		grid-auto-rows: auto;
		grid-auto-flow: row dense;
		margin-top: 40px;
		overflow: hidden;
	}
	.galleryCell {
		width: 100%;
		padding-top: 100%;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		.cellImage {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background: center center / cover no-repeat;
			transition: transform 0.5s ease-out;
		}
		.cellInfo {
			display: flex;
			align-items: center;
			padding: 20px 18px;
			background-color: ${colors.dark04};
			position: absolute;
			bottom: 0;
			right: 0;
			transition: background-color 0.5s ease-out;
			.text-caption {
				color: ${colors.white};
			}
		}

		&:hover {
			.cellImage {
				transform: scale(1.1);
			}
			.cellInfo {
				background-color: ${colors.dark03};
			}
		}
	}
	.titleIcon {
		width: 28px;
		height: 28px;
		margin-right: 10px;
		path {
			fill: ${colors.primary};
			stroke: ${colors.primary};
		}
		rect {
			stroke: ${colors.primary};
		}
	}
	.buttonRow {
		margin-top: 60px;
		text-align: center;
	}

	&:focus-visible {
		outline: none !important;
	}

	@media (max-width: ${breakpoints.tablet}) {
		.gridOut {
			padding-top: 100px;
		}
	}

	@media (max-width: ${breakpoints.mobile}) {
		.gridOut {
			padding-top: 32px;
		}
		.topNavDropdown {
			display: block;
		}
		.topNavTabs {
			display: none;
		}
	}
`

export const PopupView = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	.popupInner {
		position: absolute;
		top: 16px;
		left: 16px;
		right: 16px;
		bottom: 16px;
		display: flex;
		align-self: flex-start;
	}

	.popupBgnd {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
	}

	.popupImg {
		width: 100%;
		height: 100%;
		background: center center / contain no-repeat;
		background-color: ${colors.black};
	}
	.popupClose {
		position: absolute;
		right: 16px;
		top: 16px;
	}
	.popupInfobox {
		position: absolute;
		bottom: 16px;
		left: 16px;
		right: 16px;
		background: ${colors.dark04};
		padding: 12px;
		display: flex;
		.navArrowL, .navArrowR {
			display: inline-flex;
		}
		.navArrowL {
			margin-right: 24px;
		}
		.popupCenter {
			display: flex;
			flex-grow: 2;
			align-items: center;
			justify-content: space-between;
			padding: 0 48px;
		}
		.popupTitle {
		}
		.popupFile.text-caption{
			color: #999;
		}
		.downloadBtn {
		}
	}
	.text-caption {
		color: ${colors.white};
		text-transform: uppercase;

	}
	@media (max-width: ${breakpoints.tablet}) {
		.popupInfobox .popupCenter {
			padding: 0 24px;
		}
	}
	@media (max-width: ${breakpoints.mobile}) {
		.popupInfobox {
			flex-direction: column;
			.popupCenter{
				flex-direction: column;
			}
		}
		.popupImg {
			background-position: 50% 100px;
		}
		.popupInfobox {
			padding: 12px;
		}
		.navArrowL {
			position: absolute;
			bottom: 150px;
			left: 12px;
		}
		.navArrowR {
			position: absolute;
			bottom: 150px;
			right: 12px;
		}
		.downloadBtn {
			text-align: center;
		}
	}
`

export const TitleIcon = () => {
	return (
		<svg width="48" height="48" className="titleIcon" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.6649 21.3864L4 28.4492V38.114H43.7746V24.3602L32.2511 14.6953L19.9842 28.0774L13.6649 21.3864Z" strokeWidth="0.5" />
			<rect x="5" y="9" width="38" height="29" strokeWidth="2" />
		</svg>
	)
}
