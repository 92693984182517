import React, { useEffect } from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import Select from "react-select";
import { SelectStyles } from "./Select";
import GridContainer from "../GridContainer";
import RightLightningClippedContainer from "../RightLightningClippedContainer";

import snowImage from "../../images/snow.jpg";
//import TabData from "./MediaGalleryData";

import {
	Section,
	TitleIcon,
	PopupView,
} from "./MediaGalleryStyles";

import ButtonText from "../buttons/ButtonText";
import ButtonUI from "../buttons/ButtonUI";

const friendlySize = (bytes: string) => {
	let size: number = +bytes / 1024
	let unit = "KB"
	if (size >= 1024) {
		size /= 1024
		unit = "MB"
	}
	if (size >= 1024) {
		size /= 1024
		unit = "GB"
	}
	let sizeString = `${size.toFixed(2)}`
	sizeString = sizeString.replace(/0+$/, '')
	sizeString = sizeString.replace(/\.$/, '')
	return `${sizeString}${unit}`
}

export const fragment = graphql`
	fragment MediaItemsFragment on Contentstack_everson_2_0_media_page {
		mediaItems: media_items {
			title
			displayTitle: display_title
			mediaCategory: media_category {
				key
				title
			}
			mediaItemInfo: media_item_info {
				mediaThumbnail: media_thumbnail {
					url
				}
				mediaAsset: media_asset {
					url
				}
				downloadable
				downloadableAsset: downloadable_asset {
					url
					fileSize: file_size
				}
			}
		}
	}
`

export interface MediaGalleryData {
	title: string
	displayTitle: string
	mediaCategory: [{
		key: string
		title: string
	}]
	mediaItemInfo: {
		mediaThumbnail: {
			url: string
		}
		mediaAsset: {
			url: string
		}
		downloadable: boolean
		downloadableAsset?: {
			url: string
			fileSize: string
		}
	}
}

interface Props {
	data: MediaGalleryData[],
	downloadString: string,
};

type ItemType = {
	isOpen: boolean;
	item: any;
	index: number;
}

export default function MediaGallery(props: Props) {
	const { data, downloadString } = props
	const categories = data.flatMap(item => item.mediaCategory)
	const tabKeys = _.uniqBy(categories, category => category.key)
	const TabData = tabKeys.map(tab => {
		const filtered = data.filter(item => (
			item.mediaCategory.map(category => category.key).includes(tab.key)
		))
		return {
			tabName: tab.title,
			items: filtered.map(item => ({
				thumb: item.mediaItemInfo.mediaThumbnail.url,
				image: item.mediaItemInfo.mediaAsset.url,
				title: item.title,
				displayTitle: item.displayTitle,
				dataSize: item.mediaItemInfo.downloadableAsset?.fileSize ?? 0,
				downloadURL: item.mediaItemInfo.downloadableAsset?.url ?? '',
				downloadable: item.mediaItemInfo.downloadable
			}))
		}
	})

	const tabWidth = `${(100 / TabData.length).toFixed(2)}%`

	const [activeTab, setActiveTab] = React.useState(0)
	const [activeItem, setActiveItem] = React.useState<ItemType>({
		isOpen: false,
		item: null,
		index: 0,
	})

	const nextImage = () => {
		let nextIndex = activeItem.index + 1
		nextIndex = nextIndex % TabData[activeTab]?.items?.length
		setActiveItem({
			isOpen: true,
			item: TabData[activeTab]?.items[nextIndex],
			index: nextIndex,
		})
	}

	const prevImage = () => {
		let prevIndex = activeItem.index
		if (prevIndex === 0) {
			prevIndex = TabData[activeTab]?.items?.length
		}
		prevIndex = prevIndex - 1
		setActiveItem({
			isOpen: true,
			item: TabData[activeTab]?.items[prevIndex],
			index: prevIndex,
		})
	}

	const handleKeyPress = (e: { keyCode: number }) => {
		if (activeItem && activeItem.isOpen) {
			if (e.keyCode === 39) {
				nextImage()
			} else if (e.keyCode === 37) {
				prevImage()
			} else if (e.keyCode === 27) {
				setActiveItem({ isOpen: false, item: null, index: 0 })
			}
		}
	}

	const renderTabs = () => {
		return TabData.map((tab: { tabName: string }, index: number) => {
			const activeClass = index === activeTab ? "active" : "";
			return (
				<div className={`tabOuter ${activeClass}`} style={{width: tabWidth}} key={index} onClick={() => setActiveTab(index)}>
					<div className="text-caption">{tab?.tabName}</div>
				</div>
			)
		})
	}

	const renderImages = () => {
		if (TabData && !!TabData.length && TabData[activeTab]?.items && !!TabData[activeTab]?.items.length) {
			const allImages = TabData[activeTab]?.items.map((item: any, index: number) => {
				return (
					<div key={index} className="galleryCell" onClick={() => setActiveItem({isOpen: true, item: item, index: index})}>
						<div className="cellImage" style={{ backgroundImage: `url("${ item.thumb }")` }}></div>
						<div className="cellInfo">
							<TitleIcon />
							<div className="text-caption">{item?.displayTitle}</div>
						</div>
					</div>
				)
			});
			return allImages;
		}
	}

	const downloadImage = (url: string) => {
		window.open(url, "_new");
	}

	const options = TabData.map((tab, index) => ({
		label: tab.tabName,
		value: index,
		index: index
	}))

	return (
		<Section onKeyDown={ handleKeyPress } tabIndex={-1}>
			<div className="sectionClip" style={{ backgroundImage: `url("${ snowImage }")` }}>
				<GridContainer className="gridOut">
					<div className="gridIn" id="galleryGrid">
						<div className="topNavDropdown">
							<Select
								options={options}
								value={options[activeTab]}
								styles={SelectStyles(false)}
								onChange={(e: any) => {
									setActiveTab(e?.index)
								}}
							/>
						</div>

						<div className="topNavTabs">
							{ renderTabs() }
							<div className="tabHighlight" style={{ width: tabWidth, transform: `translateX(${ activeTab * 100 }%)` }}></div>
						</div>

						{/* Gallery Images*/}
						<div className="galleryOut">
							{ renderImages() }
						</div>
						<div className="buttonRow">
							{/* <ButtonText colorTheme="light">Load More</ButtonText> */}
						</div>
					</div>
				</GridContainer>
			</div>
			{activeItem && activeItem.isOpen && 
				<PopupView>
					<div className="popupBgnd" onClick={() => setActiveItem({ isOpen: false, item: null, index: 0 })} />
					<div className="popupInner">
						<div className="popupImg" style={{ backgroundImage: `url("${ activeItem.item?.image }")` }} />
						<div className="popupClose">
							<ButtonUI buttonType="close" colorTheme="blue" callback={() => setActiveItem({ isOpen: false, item: null, index: 0 })} />
						</div>

						{/* Image captions */}
						<div className="popupInfobox">
							<div className="nowrap">
								<ButtonUI className="navArrowL" buttonType="arrow" colorTheme="blue" callback={() => prevImage()} />
								<ButtonUI className="navArrowR" buttonType="arrow" flipped={true} colorTheme="blue" callback={() => nextImage()} />
							</div>
							<div className="popupCenter">
								<div className="popupTitle">
									{/* <div className="text-caption">{activeItem.item?.title}</div>								 */}
								</div>
								{activeItem.item?.downloadable && (
									<div className="popupFile text-caption">
										{/* <div className="nowrap">Asset type: ZIP</div> */}
										<div className="nowrap">
											{activeItem.item?.displayTitle} ({friendlySize(activeItem.item?.dataSize)})
										</div>
									</div>
								)}
							</div>
							{activeItem.item?.downloadable && (
								<ButtonText className="downloadBtn" colorTheme="blue" callback={() => downloadImage(activeItem.item?.downloadURL)}>{downloadString}</ButtonText>
							)}
						</div>
					</div>
				</PopupView>
			}
		</Section>
	)
}
