import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import ButtonText from "../buttons/ButtonText";
import SectionTitle from "../SectionTitle";
import GridContainer from "../GridContainer";
import RightLightningClippedContainer from "../RightLightningClippedContainer";
import { breakpoints, colors, zIndices } from "../../styles/variables";

import BackgroundImg from "../../images/mediaBGBottom.jpg";

const Section = styled.section`
	margin-top: -11.5vw;
	position: relative;
	padding-top: 11.5vw;
	padding-bottom: 11vw;
	.backgroundImg {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.gridOut {
		height: 100%;
	}
	.gridIn {
		grid-column: 4 / span 6;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.buttonRow {
		text-align: center;
	}

	@media (max-width: ${breakpoints.tablet}) {
		.gridIn {
			grid-column: 3 / span 4;
		}
	}
	@media (max-width: ${breakpoints.mobile}) {
		.gridIn {
			grid-column: 1 / span 2;
		}
	}
`

/*
const data = {
	title: "Media Inquiries",
	description: "In quis mi rhoncus, euismod neque non, tristique nisl. Maecenas porta dui in justo cursus, lacinia tincidunt odio auctor. Fusce quam velit, finibus at lorem vitae, ornare tempor nulla. ",
	button_text: "Contact us",
	click_url: "https://www.google.com"
};
*/

export const fragment = graphql`
	fragment MediaContactFragment on Contentstack_everson_2_0_media_page {
		mediaContact: media_page_contact_section {
			alignment
			headline
			bodyCopy: body_copy
			ctaLink: cta_link {
				title
				href
			}
		}
	}
`

export interface MediaInquiriesData {
	alignment: string
	headline: string
	bodyCopy: string
	ctaLink: {
		title: string
		href: string
	}
}

interface Props {
	data: MediaInquiriesData,
};

const MediaInquiries = (props: Props) => {
	const {
		alignment,
		headline,
		bodyCopy,
		ctaLink,
	} = props.data

	return (
		<Section>
			<div className="backgroundImg" style={{ backgroundImage: `url("${ BackgroundImg }")` }}></div>
			<GridContainer className="gridOut">
				<div className="gridIn">
					<SectionTitle colorTheme="light" title={headline}>
						{ bodyCopy }
					</SectionTitle>
					<div className="buttonRow">
						<ButtonText colorTheme="blue" callback={() => {window.open(ctaLink.href, "_new")}}>
							{ ctaLink.title }
						</ButtonText>
					</div>
				</div>
			</GridContainer>
		</Section>
	)
}

export default MediaInquiries
